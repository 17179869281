import React from 'react';

export default function Legs3() {
  return (
    <svg viewBox="0 0 500 170">
      <path
        fill="#7B6855"
        d="M396.4 101.6a32 32 0 0 1-8.2-1.8c-2.1-.9-3.3-2-3.7-3.5l1.6.8-1.2-1.6a31.8 31.8 0 0 1-3.9-12.8c-.9-6.8-1.6-11-2.3-12.7-1.5-4-4.2-6-8.1-6l-7.5.2-.2.2c-4.8 4.9-7.4 8-8 9.6l-.1 7.8a63.2 63.2 0 0 1-1 11.9v.2l.2.7c.3 2.7.5 7.2.5 13.3.2 5.7 1.1 10.3 2.6 13.7 2.4 5.6 6.4 9.4 12 11.3 2 .7 4.6 1.2 7.6 1.5h.2l.2-.2c4.3-3.9 8.3-9.2 12-15.6 2.9-5 5.5-10.5 7.7-16.3l.2-.6h-.6z"
      />
      <path
        d="M184.5 73.3c-.9-5.2-2.5-11.3-4.9-18.1l-3-9V46a29.4 29.4 0 0 0-5.3-8.8c-2.8-3-7-5.9-12.6-8.7-5.6-2.9-10.1-4.4-13.3-4.5h-9.7c-6.9.8-12.9 2.1-17.8 4-3.5 2-6.5 3.8-9 5a23.1 23.1 0 0 0-4.4 4.6 42 42 0 0 0-5.3 18.7v17.2a9.5 9.5 0 0 1 .3 3.3v.2a169.9 169.9 0 0 1 .8 10.5c.2 1.8.5 3.8 1 5.8l-1.1-.5.3 1c1 3.4 2.2 6.6 3.3 9.6 2.2 5.5 4.6 10.6 7.2 15.2 6.1 10.6 13.1 17.9 20.7 21.6h.1a92 92 0 0 0 21.6 2.3c3.5 0 7.6-2 12.2-5.7l.1-.2c2.6-4.1 6-10.5 10.2-18.8l2-3 3.1-5-.8-.1 2-2.8.9-2c.9-2.4 1.6-4.8 2-7V77.2c-.1-1.3-.3-2.7-.6-4zm212.2-26.8C389.1 36.9 376.4 32 359 32a71.5 71.5 0 0 0-44.3 13.6c-10.3 7.8-15.5 16-15.5 24.3a52.6 52.6 0 0 0 5.3 16.2l4 8.9c.6 1.4 2.3 3.5 5 6.2 3.2 3.3 5.2 5.5 6 6.5l.7 1.6v.1l.2.2.1.5 1.2 3.6.1.2 2 3.2.4.7c4.3 8.4 7.7 14.7 10.2 18.8l.1.2c4.7 3.8 8.8 5.7 12.2 5.7h5.7a102.3 102.3 0 0 0 13.3-1.7l.5-.1h.4l1.7-.5h.1c3-1.4 6-3.4 8.7-6l.8-.7-1.1-.1c-2.9-.3-5.4-.8-7.3-1.4a18.6 18.6 0 0 1-11.5-10.7 38.8 38.8 0 0 1-2.5-13.4 134.7 134.7 0 0 0-.4-15.1c.4-2.5.7-6.1.7-11v-7.4a42 42 0 0 1 7.7-9.2l7-.2c3.5 0 5.9 1.7 7.2 5.4.7 1.6 1.5 5.8 2.4 12.4a35 35 0 0 0 3.3 12.1v.6c0 2.3 1.6 4 4.4 5.2a33 33 0 0 0 8.5 1.9h.4l1.8-5 1-3.3V94l.1-.1.7-1.4-1 .5 1.2-4.5 1-4 .2-.8a69 69 0 0 0 2-17.6c0-7.1-2.3-13.7-7-19.7z"
        fill="#56483A"
      />
      <path
        d="M189.5 25.5A62.4 62.4 0 0 0 142 .9a62 62 0 0 0-52.7 24c-4.3 3-8.2 8.7-11.7 17a101.6 101.6 0 0 0-7.3 30.4A501 501 0 0 0 72.2 95c1.7 11.4 4.3 22 8 31.4 11 29 29.6 43.6 55 43.6 9.3 0 18-1.2 26-3.7a72.6 72.6 0 0 0 34.5-23.8 80.9 80.9 0 0 0 13.7-25.3l1.3-6.5c0-2.2-.1-4-.3-5.3.4-2.3.7-4.6.7-6.8 0-30.8-7.3-55.4-21.6-73zm-10 85l-2.5 3.7-2 3.1c-4.2 8.3-7.6 14.6-10 18.8-4.5 3.6-8.4 5.4-11.6 5.4-8 0-15.1-.7-21.3-2.2A50.3 50.3 0 0 1 112 118a115.2 115.2 0 0 1-10-23.4l1 .5-.3-1c-.7-2.5-1.1-4.7-1.2-6.7a169.4 169.4 0 0 0-1.2-14.2v-17a41 41 0 0 1 5.1-18.1c1.1-1.6 2.5-3 4-4.3 2.6-1.3 5.6-3 9-5a72 72 0 0 1 17.4-4h9.7a34 34 0 0 1 12.8 4.5c5.5 2.8 9.7 5.6 12.4 8.5 2 2.2 3.7 5 5 8.5l3 9.1a120.8 120.8 0 0 1 5.5 21.9v20.3c-.4 2.2-1 4.5-2 6.8l-.7 1.8-2.4 3.4-.4.5.8.3zm248.3-41.6h.1l-.1-.4-1.2-1.7a77.4 77.4 0 0 0-8.3-34A52.4 52.4 0 0 0 375 4.3h-32.8c-40.3 6.5-60.6 28-60.6 63.7l.3 8.9c.4 7 1 13.4 2 19.2 1.7 10 4.1 17.5 7.1 22l1.7 4.4a81 81 0 0 0 11.7 19.8A72.5 72.5 0 0 0 365 170c25.4 0 43.9-14.6 55-43.6a147.5 147.5 0 0 0 9.5-48.8c0-3.4-.5-6.3-1.7-8.6zm-27 14.6l-.3.8-1 4a234.8 234.8 0 0 0-1.6 5.4l-1.1.6.3.9 1.3-.5-.9 2.5-1.3 3.8-.3 1a117 117 0 0 1-7.6 16 64.3 64.3 0 0 1-11.9 15.5 35.8 35.8 0 0 1-8.3 5.8l-1.7.4h-.2a16.2 16.2 0 0 0-1.3.3 119 119 0 0 1-12.5 1.4l-5.6.1c-3.2 0-7.1-1.8-11.5-5.5-2.6-4-6-10.4-10.2-18.7l-.5-.8-1.9-3.1a101 101 0 0 0-1-3.2h.2l-.4-.8a22 22 0 0 0-.4-.5 8 8 0 0 0-.8-1.8c-.8-1-2.9-3.3-6.2-6.6a25.8 25.8 0 0 1-4.7-6 311.2 311.2 0 0 1-4.4-9.7 53.1 53.1 0 0 1-4.8-15c0-8 5-15.9 15-23.4A70.5 70.5 0 0 1 359 33c17 0 29.5 4.7 37 14.1a30.4 30.4 0 0 1 6.8 19.1 80 80 0 0 1-2 17.3z"
        fill="#171717"
      />
    </svg>
  );
}
